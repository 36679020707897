
<div class="thanks--container_father_thanks">

  <div class="thanks--container_thanks">

    <div class="thanks--container_text_thanks">
      <h2 class="thanks--txt1_thanks">
        Gracias
      </h2>
      <h2 class="thanks--txt2_thanks">
        por participar
      </h2>
    </div>

    <div class="thanks--container_img_thanks">
      <img src="../../assets/congreso_europeo/icono-corazon.png" alt="" class="thanks--img_thanks">
    </div>

  </div>

  <div class="container text-center thanks--father_container">
    <div class="share--bar thanks--container_button">
      <button routerLink="/" class="button button--white">
        <img src="../../assets/congreso_europeo/volver.png" alt="" class="thanks--img_return">
        Volver
      </button>
    </div>
  </div>

</div>

<div class="thanks--container_footer">

  <div class="thanks--container_img_footer">

    <img class="thanks--logo_footer_2" src="assets/logo-impulsando-diabetes.png" alt="">

  </div>

  <div class="thanks--container_legal_footer">

    <!-- <p class="thanks--txt_legal_footer">
      Contenido exclusivo para uso interno de Novo Nordisk®
    </p> -->

    <h4 class="thanks--code_footer">
      Novo Nordisk® e Impulsando el Cambio en Diabetes son marcas registradas propiedad de Novo Nordisk A/S. Material revisado y aprobado por la Dirección de Asuntos Médicos y Regulatorios de Novo Nordisk®. Para uso exclusivo del profesional de la salud y en Guatemala es para uso exclusivo del profesional médico. Si tiene alguna solicitud de información médica escríbanos a medicalinfo_clat@novonordisk.com

      CLA24DI00023
    </h4>

  </div>



  <div class="thanks--container_img_footer_2">

    <img class="thanks--logo_footer" src="assets/congreso_europeo/novo_nordisk_azul.png" alt="">

  </div>

</div>
