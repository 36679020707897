
  <div class="form--row">

    <form class="form" #studentForm="ngForm" (ngSubmit)="onSubmit()">

      <div class="form--group">

        <input required class="form--control" id="name" name="name" type="text" placeholder="1. Nombre y apellido"
               autocomplete="off"
               [(ngModel)]="student.name">

      </div>

      <div class="form--check col-12 col-sm-12 form--container_2">

        <input required class="form--control-check-input" id="terms" name="terms" type="checkbox"
               [(ngModel)]="student.terms">

        <label class="form-check-label ml-3" for="terms"><b>2. Acepta términos y condiciones</b></label>

      </div>

      <!--<div class="form--group">
        <div class="form--select-wrapper">
          <select required id="career" class="form--control" name="career" [(ngModel)]="student.career">
            <option value="" selected disabled>2. Carrera</option>
            <option *ngFor="let faculty of faculties" [value]="faculty.name">{{faculty.name}}</option>
          </select>
        </div>
      </div>
      <div class="form--group">
        <textarea placeholder="3. Cuéntanos un poco sobre ti y tus gustos" required [(ngModel)]="student.message" class="form--control" name="message" id="message" rows="4"></textarea>
      </div>
      <div class="form--group">
        <div class="form--select-wrapper">
          <select required class="form--control" name="frame" id="frame" [(ngModel)]="student.frame">
            <option value="" selected disabled hidden>4. Elige el marco de tu facultad</option>
            <option *ngFor="let frame of frames" [value]="frame.value">{{frame.name}}</option>
          </select>
        </div>
      </div>-->

      <div class="form-group">

        <button type="submit" [disabled]="!studentForm.form.valid" class="button button--red form--button">
          <img src="../../assets/congreso_europeo/tomar_de_nuevo.png" alt="">

          3. Tómate una foto, 1,2,3...¡Sonría!
        </button>

      </div>

    </form>

  </div>


