
<div class="home--container_father">
  <div class="home--container_form">


    <div class="home--title_form">

      <h2 class="home--title">
        Bienvenido/a
      </h2>

      <h3 class="home--subtitle">
        Gracias por conmemorar esta fecha junto a nosotros
      </h3>

    </div>


    <div class="home--container_opt_form">

      <div class="home--opt_form">

        <div class="home--number_form">
          <h1 class="home--number">
            1
          </h1>
        </div>

        <div class="home--label_opt">
          <h4 class="home--name_label">
            Ingrese su nombre y apellido, acepte los términos y condiciones y haga clic en el botón
          </h4>
        </div>

      </div>

      <img src="assets/congreso_europeo/linea_2.png" alt="" class="home--line_items">

      <div class="home--opt_form">

        <div class="home--number_form">
          <h1 class="home--number">
            2
          </h1>
        </div>

        <div class="home--label_opt">
          <h4 class="home--name_label">
            Ubíquese dentro del recuadro y haga clic en el botón “tomar fotografía”
          </h4>
        </div>

      </div>

      <img src="assets/congreso_europeo/linea_2.png" alt="" class="home--line_items">

      <div class="home--opt_form">

        <div class="home--number_form">
          <h1 class="home--number">
            3
          </h1>
        </div>

        <div class="home--label_opt">
          <h4 class="home--name_label">
            Verifique su fotografía y envíela, o en caso de ser necesario, tómela nuevamente
          </h4>
        </div>

      </div>

      <img src="assets/congreso_europeo/linea_2.png" alt="" class="home--line_items">

      <div class="home--opt_form">

        <div class="home--number_form">
          <h1 class="home--number">
            4
          </h1>
        </div>

        <div class="home--label_opt">
          <h4 class="home--name_label">
            Cuando su fotografía haya sido procesada con éxito, podrá descargarla en su dispositivo
          </h4>
        </div>

      </div>

      <div class="home--button">
        <button routerLink="register" queryParamsHandling="preserve" class="home--button_form">
          <img src="assets/congreso_europeo/tomar_de_nuevo.png" alt="" class="home--icon_camera_form">Tómese una foto 1,2,3... ¡Sonría!
        </button>
      </div>

    </div>



  </div>
</div>


<!-- <img class="home--logo_corazon home--desktop-only" src="../../assets/congreso_europeo/logo_corazon.png" alt="" *ngIf="showLogo">
<img class="home--logo_2 home--responsive-only" src="assets/congreso_europeo/logo_corazon_blanco.png" alt="" > -->



