import {Component, OnInit} from '@angular/core';
import {FACULTIES} from "./mock-faculties";
import {FRAMES} from "./mock-frames";
import {Student} from "../shared/student.model";
import {Router} from "@angular/router";
import { AppComponent } from '../app.component';

@Component({
    selector: 'app-form',
    templateUrl: './form.component.html',
    styleUrls: ['./form.component.scss']
})
export class FormComponent implements OnInit {

    showLogo2 = true;
    public faculties = FACULTIES;

    public frames = FRAMES;

    public student: Student;

    constructor(
        private router: Router,
    ) {
    }

    ngOnInit(): void {
        this.student = new Student();
    }

    onSubmit() {
        localStorage.setItem('student', JSON.stringify(this.student))
        this.router.navigate(['photo'], {queryParamsHandling: 'preserve'});
    }
}
