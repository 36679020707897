<div class="share">
	<div>
		<img *ngIf="frame" src="{{frame}}" alt="" class="img--container">
	</div>
	<div class="share--bar">
		<button routerLink="/thanks" class="button button--white">
			<img src="../../assets/congreso_europeo/tomar_de_nuevo.png" alt="">
			Finalizar
		</button>
		<a href="https://{{decodedImageURL}}" download="Día mundial del corazón.png" class="share--link button button--white">
			<img src="../../assets/congreso_europeo/descargar.png" alt="">
			Descargar
		</a>
	</div>

</div>
