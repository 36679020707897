import {AfterViewInit, Component, OnInit} from '@angular/core';
import {Router} from "@angular/router";
import {Student} from "../shared/student.model";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
    selector: 'app-share',
    templateUrl: './share.component.html',
    styleUrls: ['./share.component.scss']
})
export class ShareComponent implements OnInit {

    public frame: string;
    public imageURL: string;
    public decodedImageURL: string;

    constructor(private router: Router, private domSanitizer: DomSanitizer) {
        localStorage.clear();

        if (typeof this.router.getCurrentNavigation().extras.state === 'undefined') {
            this.router.navigate(['/']);
            return;
        }

        this.frame = this.router.getCurrentNavigation().extras.state.frame || '';
        this.imageURL = this.router.getCurrentNavigation().extras.state.imageURL || '';
        this.decodedImageURL = decodeURIComponent(this.imageURL);
    }

    ngOnInit(): void {
    }

    shareOnFacebook($event): void {
        $event.preventDefault();
        window.open(`https://facebook.com/sharer/sharer.php?u=${this.imageURL}`, 'facebook_share',
            'height=320, width=640, toolbar=no, menubar=no, scrollbars=no, resizable=no, location=no, directories=no, status=no')
    }
}
