<main class="app">

  <img class="app--logo1" src="assets/logoCardio.png" alt="Logo Paciente con Diabetes">
  <img class="app--logo2" src="assets/logo_sociedad_peruana.png" alt="Logo Sociedad Peruana de Nefrología">
  <img class="app--logo3" src="assets/Logo-Sociedad-Peruana-cardiologia.png" alt="Logo de Sociedad Peruana de Cardiologia">
	<img class="app--background" src="assets/FondoCardio.png" alt="">
  <div class="container app--container-foto-inicio">
	  <router-outlet></router-outlet>
  </div>
  <img class="app--logo_2" src="assets/congreso_europeo/Logo_Dia_ACV.png" alt="">
  <img class="app--logo" src="assets/congreso_europeo/novo_nordisk_azul.png" alt="" *ngIf="showLogo">
  <img class="app--logo_3" src="assets/logo-impulsando-diabetes.png" alt="" *ngIf="showLogo">
</main>
