import {AfterViewInit, Component, ElementRef, NgZone, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {PhotoService} from "../photo.service";
import {FrameService} from "../frame.service";
import {Student} from "../shared/student.model";
import {StudentService} from "../student.service";

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.scss']
})
export class WebcamComponent implements OnInit, AfterViewInit {

  @ViewChild('video')
  public video: ElementRef<HTMLVideoElement>;

  @ViewChild('canvas')
  public canvas: ElementRef<HTMLCanvasElement>;

  public photo: any;
  public ancho: any;
  public alto: any;
  public mobile: any;

  private student: Student;

  public disabled = false;

  constructor(private router: Router,
              private photoService: PhotoService,
              private frameService: FrameService,
              private studentService: StudentService,
              private ngZone: NgZone,
              private activatedRoute: ActivatedRoute) {
  }

  ngOnInit(): void {
    if (!localStorage.getItem('student')) {
      this.router.navigate(['/'], {queryParamsHandling: 'preserve'})
    }

  }


  ngAfterViewInit(): void {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({video: true}).then((stream) => {


        this.canvas.nativeElement.width = stream.getVideoTracks()[0].getSettings().width;
        this.canvas.nativeElement.height = stream.getVideoTracks()[0].getSettings().height;
        this.video.nativeElement.srcObject = stream;
        this.video.nativeElement.play();


        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
          this.mobile=1;
        }else{ //NOT Mobile
          this.mobile=0;
        }


        /*const video = document.querySelector('video');
        const videoTracks = stream.getVideoTracks();
        console.log('Got stream with constraints:', constraints);
        console.log(`Using video device: ${videoTracks[0].label}`);
        window.stream = stream; // make variable available to browser console
        video.srcObject = stream;*/

      })
    }
  }

  public resetPhoto() {
    this.photo = "", navigator.mediaDevices && navigator.mediaDevices.getUserMedia && navigator.mediaDevices.getUserMedia({video: !0})
      .then(e => {
      let i = e.getVideoTracks()[0].getSettings().width, s = e.getVideoTracks()[0].getSettings().height;
      this.canvas.nativeElement.width = i, this.canvas.nativeElement.height = s, this.video.nativeElement.srcObject = e, this.video.nativeElement.play()
    })
  }

  public takePicture() {
    const video = this.video.nativeElement;
    const canvas = this.canvas.nativeElement;
    const context = canvas.getContext('2d');
    const videoWidth = video.videoWidth;
    const videoHeight = video.videoHeight;

    canvas.width = videoWidth;
    canvas.height = videoHeight;

    context.drawImage(video, 0, 0, videoWidth, videoHeight);
    this.photo = canvas.toDataURL("image/png");
  }

  public sendPicture() {

    this.student = JSON.parse(localStorage.getItem('student'));
    this.student.career = 'default';

    const studentPhoto = new Image();
    studentPhoto.src = this.photo;
    console.log(studentPhoto.width);

    const frame = new Image();
    frame.src = "../../assets/frames/" + this.frameService.getFrame(this.student.career, this.student.frame);

    console.log(frame.src);
    console.log(this.frameService.getFrame(this.student.career, this.student.frame));

    frame.src = "https://cardio.photobooth.eldiamundialdelcorazon.com/assets/congreso_europeo/marco-cardio.png";

    frame.onload = () => {
      const canvas = document.createElement('canvas');

      let dimensions = [];
      let positions = [];
      let textPosition = [];
      let maxWidth;

      switch (this.student.frame) {
        case 'instagram': // Vertical
          dimensions = [800, 800];
          positions = [50, 50];
          textPosition = [380, 330];
          maxWidth = 500;
          break;
        case 'facebook': // Horizontal
          dimensions = [1200, 630];
          positions = [320, 315];
          textPosition = [650, 240];
          maxWidth = 450;
          break;
      }

      canvas.width = dimensions[0];
      canvas.height = dimensions[1];

      const context = canvas.getContext('2d');

      if (this.mobile == 1) {
        const aspectRatio = studentPhoto.width / studentPhoto.height;
        const maxHeight = 800;
        const maxWidth = 960;

        let imageWidth = maxHeight * aspectRatio;
        let imageHeight = maxHeight;

        if (imageWidth > maxWidth) {

          imageWidth = maxWidth;
          imageHeight = imageWidth / aspectRatio;
        }


        const imageX = (dimensions[0] - imageWidth) / 2;
        const imageY = (dimensions[1] - imageHeight) / 2;

        context.drawImage(studentPhoto, imageX, imageY, imageWidth, imageHeight);
      } else {

        const xOffset = (dimensions[0] - 880) / 2;
        const yOffset = 40;
        const imageWidth = 880;
        const imageHeight = 675;

        context.drawImage(studentPhoto, xOffset, yOffset, imageWidth, imageHeight);
      }

      context.drawImage(frame, 0, 0);
      context.restore();

      //context.font = "30px Roboto";
      //context.fillStyle = "white";
      //context.fillText('Hola soy,', textPosition[0], textPosition[1]);
      //context.fillText(this.student.name, textPosition[0], textPosition[1] + 30);
      //this.wrapText(context, this.student.message, textPosition[0], textPosition[1] + 60, maxWidth, 30);


      canvas.toBlob((blob) => {
        this.student.image = new File([blob], 'image', {type: blob.type});

        this.ngZone.run(() => {
          this.activatedRoute.queryParams.subscribe(params => {

            if (typeof params['id'] !== 'undefined') {
              this.student.assistantId = params['id'];
            }

            this.disabled = true;
            this.studentService.add(this.student).subscribe((student) => {
              this.disabled = false;

              //this.notify.succes("Se ha guardado tu foto exitosamente.");

              this.router.navigate(['share'], {
                state: {
                  frame: canvas.toDataURL('image/jpeg'),
                  imageURL: student.image
                }
              });
            });
          })

        })
      });
    }
  }

  /*private wrapText(context, text, x, y, maxWidth, lineHeight) {

    var words = text.split(' '),
      line = '',
      lineCount = 0,
      i,
      test,
      metrics;

    for (i = 0; i < words.length; i++) {
      test = words[i];
      metrics = context.measureText(test);
      while (metrics.width > maxWidth) {
        // Determine how much of the word will fit
        test = test.substring(0, test.length - 1);
        metrics = context.measureText(test);
      }
      if (words[i] != test) {
        words.splice(i + 1, 0, words[i].substr(test.length))
        words[i] = test;
      }

      test = line + words[i] + ' ';
      metrics = context.measureText(test);

      if (metrics.width > maxWidth && i > 0) {
        context.fillText(line, x, y);
        line = words[i] + ' ';
        y += lineHeight;
        lineCount++;
      } else {
        line = test;
      }
    }

    context.fillText(line, x, y);
  }*/
}

