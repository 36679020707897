export const FRAMES = [
    {
        name: 'Facebook',
        value: 'facebook'
    },
    {
        name: 'Instagram',
        value: 'instagram'
    },
]
