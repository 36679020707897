export const FACULTIES = [
  {
    name: 'default',
    facebookFrame: 'MARCO_ADMINISTRACION_FB.png',
    instagramFrame: 'MARCO_CORAZON.png',
  },
  {
    name: 'Escuela de administración',
    facebookFrame: 'MARCO_ADMINISTRACION_FB.png',
    instagramFrame: 'MARCO_ADMINISTRACION_INSTA.png',
  },
  {
    name: 'Escuela de ciencias humanas',
    facebookFrame: 'MARCO_CIENCIAS_HUMANAS_FB.png',
    instagramFrame: 'MARCO_CIENCIAS_HUMANAS_INSTA.png',
  },
  {
    name: 'Facultad de creación',
    facebookFrame: 'MARCO_CREACION_FB.png',
    instagramFrame: 'MARCO_CREACION_INSTA.png',
  },
  {
    name: 'Facultad de economía',
    facebookFrame: 'MARCO_ECONOMIA_FB.png',
    instagramFrame: 'MARCO_ECONOMIA_INSTA.png',
  },
  {
    name: 'Facultad de estudios internacionales, políticos y urbanos',
    facebookFrame: 'MARCO_ECONOMIA_FB.png',
    instagramFrame: 'MARCO_EST_INTERNACIONALES_INSTA.png',
  },
  {
    name: 'Escuela de ingeniería, ciencia y tecnología',
    facebookFrame: 'MARCO_INGENIERIA_FB.png',
    instagramFrame: 'MARCO_INGENIERIA_INSTA.png',
  },
  {
    name: 'Facultad de jurisprudencia',
    facebookFrame: 'MARCO_JURISPRUDENCIA_FB.png',
    instagramFrame: 'MARCO_JURISPRUDENCIA_INSTA.png',
  },
  {
    name: 'Escuela de medicina y ciencias de la salud',
    facebookFrame: 'MARCO_MEDICINA_FB.png',
    instagramFrame: 'MARCO_MEDICINA_INSTA.png',
  },
  {
    name: 'Facultad de ciencias naturales y matemáticas',
    facebookFrame: 'MARCOS_PCIENCIAS_NATURALES_FB.png',
    instagramFrame: 'MARCOS_PCIENCIAS_NATURALES_INSTA.png',
  },
]
