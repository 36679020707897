import {Injectable} from '@angular/core';
import {FACULTIES} from "./form/mock-faculties";

@Injectable({
    providedIn: 'root'
})
export class FrameService {
    private faculties = FACULTIES;

    constructor() {
    }

    getFrame(career, type) {
        const faculty = this.faculties.find(faculty => faculty.name === career);

        switch (type) {
            case 'facebook':
                return faculty.facebookFrame
            case 'instagram':
                return faculty.instagramFrame
        }
    }
}
