import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Student} from "./shared/student.model";
import {environment} from "../environments/environment";

@Injectable({
  providedIn: 'root'
})
export class StudentService {

  constructor(private http: HttpClient) { }

  add(student: Student) {
    const data = new FormData();
    data.append('name', student.name);
    data.append('career', student.career);
    data.append('message', student.message);
    data.append('image', student.image);

    if (student.assistantId) {
      data.append('assistantId', student.assistantId);
    }

    return this.http.post<Student>(`${environment.url}/students`, data, {
      headers: {
        'Accept' : 'application/json'
      }
    });
  }
}
