<div class="webcam">
	<img class="webcam--photo" [src]="photo" alt="" *ngIf="photo">
  <div class="webcam--container_video" *ngIf="!photo">
    <video  autoplay playsinline id="video" #video *ngIf="!photo"></video>
  </div>

	<canvas id="canvas" #canvas></canvas>

	<div style="display: flex; justify-content: center" class="webcam--container_responsive">

		<button (click)="takePicture()" class="button button--blue form--button" *ngIf="!photo">
			<img src="../../assets/congreso_europeo/tomar_de_nuevo.png" alt="">
			Tomar fotografía
		</button>

		<button [disabled]="disabled" (click)="sendPicture()" class="button button--blue form--button mr-2" *ngIf="photo">
			<img src="../../assets/camera.png" alt="">
			Enviar fotografía
			<div *ngIf="disabled" class="spinner-border ml-2" role="status" style="color: #454546">
			</div>
		</button>

    <button [disabled]="disabled" (click)="resetPhoto()" class="button button--blue form--button mr-2" *ngIf="photo">
		<img src="../../assets/camera.png" alt="">
      	Volver a tomar foto
    </button>
	</div>
</div>

