import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  showLogo = true;
  showLogo2 = false;
  title = 'photobooth-app';

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {

        const hideLogo = this.activatedRoute.firstChild?.snapshot.data?.hideLogo;
        this.showLogo = hideLogo !== true;
        const hideLogo2 = this.activatedRoute.firstChild?.snapshot.data?.hideLogo;
        this.showLogo2 = hideLogo2 !== false;
      }
    });
  }
}
