import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PhotoService {

  constructor() {
  }

  crop(src, mobile) {
    return new Promise((resolve, reject) => {
      const photo = new Image();
      photo.src = src;
      let imageURL = '';

      photo.onload = () => {
        console.log('width: ' + photo.naturalWidth + ' Height: ' + photo.naturalHeight);
        const photoWidth = photo.naturalWidth;
        const photoHeight = photo.naturalHeight;

        const inputImageAspectRatio = photoWidth / photoHeight;

        let outputWidth = photoWidth;
        let outputHeight = photoHeight;
        if (inputImageAspectRatio > 1) {
          outputWidth = photoHeight;
        } else if (inputImageAspectRatio < 1) {
          outputHeight = photoWidth;
        }

        let outputX = (outputWidth - photoWidth) / 2;
        let outputY = (outputHeight - photoHeight) / 2;

        if(mobile==1){ //Is Mobile
          outputWidth = 480;
          outputHeight = photoHeight;
          outputX = 0;
          outputY = 0;
        }

        const outputImage = document.createElement('canvas');

        outputImage.width = outputWidth;
        outputImage.height = outputHeight;

        const ctx = outputImage.getContext('2d');
        ctx.drawImage(photo, outputX, outputY, outputWidth, outputHeight);

        imageURL = outputImage.toDataURL("image/png");

        resolve(imageURL);
      }
    })
  }

}
