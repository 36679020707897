import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {AppRoutingModule} from './app-routing.module';
import {FormComponent} from './form/form.component';
import {WebcamComponent} from './webcam/webcam.component';
import {ShareComponent} from './share/share.component';
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import { ThanksComponent } from './thanks/thanks.component';

@NgModule({
    declarations: [
        AppComponent,
        HomeComponent,
        FormComponent,
        WebcamComponent,
        ShareComponent,
        ThanksComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        HttpClientModule,
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}
