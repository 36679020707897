import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from "./home/home.component";
import {FormComponent} from "./form/form.component";
import {WebcamComponent} from "./webcam/webcam.component";
import {ShareComponent} from "./share/share.component";
import {ThanksComponent} from "./thanks/thanks.component";

const routes: Routes = [
    {
        path: '', component: HomeComponent, data: { hideLogo: false , hideLogo2: true}
    },
    {
      path: 'home', component: HomeComponent, data: { hideLogo: false , hideLogo2: true }
    },
    {
        path: 'register', component: FormComponent,
      },
    {
        path: 'photo', component: WebcamComponent,
    },
    {
        path: 'share', component: ShareComponent,
    },
    {
        path: 'thanks', component: ThanksComponent, data: { hideLogo: true }
    },
];

@NgModule({
    declarations: [],
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
